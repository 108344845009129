import React from "react";
import { Text, Heading, Box } from "@primer/react";
import './Hero.css'

const HeroIntro = () => {
  return (
    <>
      <Box
        display="flex"
        sx={{
          width: "100%",
          backgroundColor: "canvas.subtle",
        }}
        
      >
        <Box
          sx={{
            maxWidth: "1300px",
            width: ["90%", "90%", "90%", "95%", "87%"],
            margin: "auto",
            marginTop: 8,
          }}
        >
          <Heading
         
            as="h1"
            sx={{
              fontWeight: '800 !important',
              fontSize: '42px',
              marginBottom: 3,
              
            }}
          >
            I’m a strategic and driven Product Designer (ex GitHub)
          </Heading>

          <Text
            sx={{
              fontSize: 3,
              color: "fg.default",
            }}
          >
           


Armed with the 5+ years of experience I was recently a Product Designer at GitHub. I specialize in implementing System Thinking, within an Agile environment 
for Enterprise products. On a lighter note, Im a huge book worm and love to read!
          </Text>
          <Box sx={{
            paddingTop: 3,
          }}>
          <div 
           className="heroIntro"
          />
          </Box>
          
        </Box>
      </Box>
      
      
    </>
  );
};

export default HeroIntro;
