import React from "react";

const Interests = () => {
    return (
        <>
            Interests
        </>
    )
}

export default Interests 