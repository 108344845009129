import github from '../assets/projectCardImages/github.webp'
import trans from '../assets/projectCardImages/trans.webp'
import market from '../assets/projectCardImages/market.webp'
import king from '../assets/projectCardImages/king.webp'
import clap from '../assets/projectCardImages/clap.webp'
import ver from '../assets/projectCardImages/ver.png'
import vmart from '../assets/projectCardImages/vmart.webp'
import success from '../assets/projectCardImages/success.png'
import comesoon from '../assets/projectCardImages/comesoon.png'
export const projectData = [
    {
        id: 12,
        image: success,
        categories: ['B2B', 'B2C', 'Web', 'Branding'],
        title: "GitHub Success Portal - Building a Portal that will enable Enterprise Admins to increase developer productivity with the help of Copilot AI ",
        desc: " In this project, I conceptualized and designed the GitHub Success Portal, a groundbreaking platform tailored for Enterprise Admins to enable their developer's productivity.",
        link: '/github-success'
    },
    { 

        id: 1, 
        image: github,
        categories: ['B2B', 'B2C', 'Web', 'Branding'],
        title: 'GitHub - Revamping the Support Portal to Facilitate the Resolution of Challenges Encountered by All Members of the GitHub Community',
        desc: 'In overhauling the support portal, my focus is on user-centric design, ensuring intuitive navigation, responsive design across devices.. ',
        link: '/github'
    },
    

    {
        id: 5,
        image: vmart,
        categories: ['B2C', 'Mobile', 'Web', 'Branding'],
        title: 'V-Mart - Creating readily available Vietnamese grocery products not typically available in surrounding markets.',
        desc: ' Given the scarcity of Vietnamese markets, both in rural and urban locales, accessing specific ingredients poses a challenge. Objective? Facilitate convenience of Vietnamese groceries.',
        link: '/vmart'
    },
    // {
    //     id: 2,
    //     image: trans,
    //     categories: ['B2B', 'Web', 'Branding'],
    //     title: 'Transport Pro - Inspiring Clear & Confident Communication between Drivers, Load Managers and Brokers',
    //     desc: 'I proactively identified friction in load and driver discovery, proposed redesigning it, and helped prioritize it on the product roadmap.',
    //     link: '/transportpro'
    // },
    {
        id: 4,
        image: king,
        categories: ['B2B', 'B2C', 'Web', 'Branding'],
        title: 'King Metals - Redesigning an e-commerce site with the aim of streamlining the purchasing flow',
        desc: 'I spearheaded the conceptualization and design of novel UI and frontend components, a revitalization of the brand and the efficiency of the purchasing process.',
        link: '/king'
    },
    {
        id: 6,
        image: clap ,
        categories: ['B2C', 'Mobile'],
        title: 'Clapper - A Platform Facilitating Freedom of Speech on Any Subject',
        desc: 'Pioneering the research initiative for a groundbreaking social media app, I orchestrated the conversations to discern the gaps in todays social media landscape.',
        link: '/clapper'
    },
    //hello wolrd

   
    
    // {
    //     id: 3,
    //     image: market,
    //     categories: ['B2B', 'Web', 'Branding'],
    //     title: 'Marketdeus - Streamlining the Email Marketing Process for Companies and their customers',
    //     desc: 'As the designer and developer for this marketing software, I played a pivotal strategic role, driving initiatives that doubled user retention in our A/B testing',
    //     link: '/market'
    // }  
]